import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BlogTable } from "../../../components/Blog/postElements/BlogTable";
import { BannerMobileApp } from "../../../components/Blog/postElements/BannerMobile";
import { PostIntro } from "../../../components/Blog/postElements/PostIntro";
import { ImageMaxWidth } from "../../../components/Blog/postElements/ImageMaxWidth";
import { FullWidthImage } from "../../../components/Blog/postElements/FullWidthImage";
export const _frontmatter = {
  "title": "Tech: Why WebRTC Is Best For Your Video-Chat App",
  "authors": ["Marek Gocał"],
  "abstract": "WebRTC technology that allows you to establish a direct P2P, lossless audio/video connection. Where is WebRTC used? What are the WebRTC limitations? How does WebRTC works? Read the article and learn everything about this technology.",
  "imageSource": "./main.png",
  "link": "webrtc-part-2-how-does-it-work",
  "date": "2022-02-21T06:32:51.343Z",
  "seoTitle": "Tech: Why WebRTC Is Best For Your Video-Chat App",
  "seoDescription": "WebRTC technology that allows you to establish a direct P2P, lossless audio/video connection. Where is WebRTC used? What are the WebRTC limitations? How does WebRTC works? Read the article and learn everything about this technology.",
  "seoKeyPhrase": "webrtc, flutter",
  "seoKeyWords": "WebRTC, signaling, p2p, ICE, TURN server, NAT, Twillio, Google, P2P, Telemedicine, Video chat, audio, video, teleconferencing, tele conference, tele-medicine apps, how to build audio video app, how to build telemedicine, how to integrate video chat",
  "categories": ["Technology", "WebRTC", "Video-Chat", "Telemedicine"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PostIntro title="Introduction" description={<p>Welcome to the second part of this article about WebRTC. In part one, we discussed this technology from a business perspective. We looked at its benefits, its typical uses and its limitations. This part has a more technological point of view. We&#39;ll start</p>} imageUrl={"/img/whyWebRTCisBest/main.png"} mdxType="PostIntro" />
    <p>{`with an in-depth look at the platform, its compatibility with web browsers and how that may change in the future. We will also go step by step through how WebRTC works, examining the main concepts related to the functioning of the technology.`}</p>
    <p>{`Let’s get started!`}</p>
    <h2 {...{
      "id": "Compatibility"
    }}>{`Compatibility`}</h2>
    <p>{`In the previous article, we noted that most modern browsers are WebRTC compatible. Of course, we do not see full support in Internet Explorer or DuckDuckGo. Still, these are marginal players in the web browser market. So first, let`}{`’`}{`s see what distinguishes the implementation of WebRTC seen in the desktop and mobile versions.`}</p>
    <h2 {...{
      "id": "Desktop-browser"
    }}>{`Desktop browser`}</h2>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/webrtcbrowser.png" imageAlt='webrtc browser support' mdxType="FullWidthImage" />
    <p>{`Before we go through the currently supported desktop browsers, let`}{`’`}{`s take a closer look at the worldwide market share.`}</p>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/webrtcbrowsershare.png" imageAlt='webrtc browser share' mdxType="FullWidthImage" />
    <p><em parentName="p">{`Source:`}</em>{` `}<a parentName="p" {...{
        "href": "https://gs.statcounter.com/browser-market-share/desktop/worldwide",
        "target": "_blank",
        "rel": "nofollow"
      }}><em parentName="a">{`https://gs.statcounter.com/browser-market-share/desktop/worldwide`}</em></a></p>
    <p>{`As we can see, virtually the entire market belongs to Google, Apple, and Mozilla. Moreover, the statistics show that IE is still in use. Still, it is reasonable to assume that these users are unlikely to use WebRTC, public devices in libraries, for example.`}</p>
    <p>{`The list of supported browsers by WebRTC is, therefore, as follows.`}</p>
    <ul>
      <li parentName="ul">{`Microsoft Edge 15+`}</li>
      <li parentName="ul">{`Google Chrome 23+`}</li>
      <li parentName="ul">{`Mozilla Firefox 22+`}</li>
      <li parentName="ul">{`Safari 11+`}</li>
      <li parentName="ul">{`Opera 18+`}</li>
      <li parentName="ul">{`Vivaldi 1.9+`}</li>
      <li parentName="ul">{`Brave.`}</li>
    </ul>
    <p>{`Users will need to make sure they connect suitable input and output devices for communication. However, WebRTC is compatible with all microphones, cameras, and other communication devices connected to computers, so restrictions are rare.`}</p>
    <p>{`When it comes to mobile devices, all the required hardware is built-in on the device. However, as we mentioned before, there are some restrictions. For example, before 2020, most mobile browsers did not support WebRTC. However, most mobile browsers now offer support.`}</p>
    <p>{`You can check support for WebRTC at: `}<a parentName="p" {...{
        "href": "http://caniuse.com/#feat=rtcpeerconnection",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`WebRTC Peer-to-peer connections`}</a></p>
    <ul>
      <li parentName="ul">{`Google Chrome 91+`}</li>
      <li parentName="ul">{`Mozilla Firefox 89+`}</li>
      <li parentName="ul">{`Safari 11+`}</li>
      <li parentName="ul">{`Opera 62+`}</li>
      <li parentName="ul">{`Samsung Internet 4+`}</li>
    </ul>
    <p>{`Please note that while this information is from an official source, it is not always totally accurate! WebRTC is a complex technology, and some of the officially supported mobile browsers struggle to provide a usable implementation.`}</p>
    <h2 {...{
      "id": "iOS-support"
    }}>{`iOS support`}</h2>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/ios.png" imageAlt='webrtc browser support' mdxType="FullWidthImage" />
    <p>{`According to the official information, mobile Safari has supported WebRTC since version 11. Today, we`}{`’`}{`ve reached version 14, and the support remains only a theory. Moreover, the implementation is hugely buggy; as soon as one problem is solved, three more appear! Therefore, we strongly advise against relying on this solution. It will only generate unnecessary costs and lead to a poorly performing product.`}</p>
    <p>{`Other browsers on this Apple platform do not support WebRTC, and we do not expect to see it any time soon. The iOS guidelines prevent browsers that do not use Webkit as a rendering engine, and WebRTC is unavailable within the Webkit Webview on iPhones.`}</p>
    <h2 {...{
      "id": "Android-support"
    }}>{`Android support`}</h2>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/android.png" imageAlt='webrtc browser support' mdxType="FullWidthImage" />
    <p>{`Things are a bit better here. These three popular browsers offer perfect support:`}</p>
    <ul>
      <li parentName="ul">{`Google Chrome`}</li>
      <li parentName="ul">{`Firefox`}</li>
      <li parentName="ul">{`Android browser.`}</li>
    </ul>
    <p>{`The support is patchy in the other Android browsers:`}</p>
    <ul>
      <li parentName="ul">{`Samsung Internet. This browser has over 1 billion downloads. It requires the addition of the Webkit prefix for WebRTC, so it probably uses an older WebRTC release and this is likely to cause issues in the long run.`}</li>
      <li parentName="ul">{`QQ Browser and Baidu Browser. Here the situation is similar to Samsung Internet, as described above.`}</li>
      <li parentName="ul">{`Opera. Support is not as good as with Firefox, for example, but it works reasonably well nonetheless.`}</li>
      <li parentName="ul">{`Opera mini. Not supported.`}</li>
      <li parentName="ul">{`UC Browser. Not supported.`}</li>
    </ul>
    <p>{`It is worth remembering that these are not the only browsers available. Not all users will have one installed that supports WebRTC.`}</p>
    <BannerMobileApp title="" description={"Do you want to create a video-chat application?"} mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "Mobile-apps"
    }}>{`Mobile apps`}</h2>
    <p>{`With mobile applications, the support issues found in mobile browsers do not apply. However, we should bear in mind certain limitations and problems that developers still must overcome. Without going deep into the technical aspects, phones are more complex to configure than web applications. They usually have weaker processors, so their computing power is lower than PCs. On the other hand, they have many more sensors providing information, such as geolocation, which may be a source of valuable data. Another concern is the management of a relatively small battery. Developers must ensure their application does not drain the phone battery too quickly. Fortunately, we can enjoy full support for WebRTC on mobile platforms once we deal with these factors.`}</p>
    <p>{`Even more fortunate is that mobile applications can access many 3rd party SDKs, which makes the implementation of WebRTC even more efficient. Due to this, developers are free of the limits of native-only technologies, requiring two separate applications, one for IOS and another for Android. Instead, we can use a cross-platform technology, such as Flutter, to minimise costs and create high-quality applications characterised by high performance.`}</p>
    <h2 {...{
      "id": "How-does-WebRTC-work"
    }}>{`How does WebRTC work?`}</h2>
    <p>{`With WebRTC, users can communicate without the need to transfer information via a server. In the last part of the article, we have already mentioned that WebRTC uses a P2P protocol. As a result, users can communicate in real-time with no visible delay.`}</p>
    <p>{`The challenge with P2P is to establish a connection. To do this, WebRTC uses a process called signalling.`}</p>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/howitworks1.png" imageAlt='How does WebRTC work' mdxType="FullWidthImage" />
    <p>{`Signalling is an exchange of information that allows a P2P session between users to start. This information includes:`}</p>
    <ul>
      <li parentName="ul">{`The media types to be shared`}</li>
      <li parentName="ul">{`User addresses`}</li>
      <li parentName="ul">{`Data defining how and when to start or stop a connection`}</li>
      <li parentName="ul">{`How to find each other on the network`}</li>
      <li parentName="ul">{`and any error messages required.`}</li>
    </ul>
    <p>{`This mechanism, however, is not a part of the WebRTC API, which means two things:`}</p>
    <ul>
      <li parentName="ul">{`You have to build it yourself.`}</li>
      <li parentName="ul">{`You can use whatever communication mechanism you want.`}</li>
    </ul>
    <p>{`Theoretically, it would be possible to achieve this with regular emails but, today, WebSockets is the better solution. We won`}{`’`}{`t explain how to build a signalling server in this article, but please note that the server would need to process many messages with high levels of concurrency for larger applications. Therefore, when building your server, you should prepare for a high load. Likewise, when choosing an off-the-shelf solution, be prepared for relatively high costs.`}</p>
    <p>{`Now let`}{`’`}{`s move to the next challenge of establishing a P2P connection, called the NAT (Network Address Translation) problem. Most networks are behind NAT devices. In addition, many layers of routers, firewalls, and other Internet hardware also conceal users`}{`’`}{` IP addresses. As a result, the users`}{`’`}{` devices don`}{`’`}{`t usually have a public IP address or might not have permission to establish a direct connection. Let`}{`’`}{`s explain the problem using a telephone conversation as an analogy.`}</p>
    <p>{`When we try to call someone, we dial their phone number, and they only have to accept or reject the call. It`}{`’`}{`s that simple because each phone number has only one device assigned to it, usually defined by a sim card inside the device. On the Internet, the matter is a bit more complicated.`}</p>
    <p>{`On the Internet, the IP address is analogous to a telephone number. Before IPv6, there weren`}{`’`}{`t enough addresses for each device that wanted to connect to the network. For this reason, public groups containing several IP addresses, each with one public address, were created. You can see this situation in the diagram below, where the Administrator address hides the IP address.`}</p>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/howitworks2.png" imageAlt='Task server' mdxType="FullWidthImage" />
    <p>{`A peer to peer connection requires an individual`}{`’`}{`s device address to identify the device, similar to the need for a phone number when making a call. ICE (Interactive Connectivity Establishment) handles this part of the process.`}</p>
    <p>{`First, an ICE agent finds out if you are behind any NAT (Network Address Translation). To do this, it sends a request to the STUN (Session Traversal Utilities for NAT) server. If everything goes smoothly, the STUN server then returns the user`}{`’`}{`s address and port. The diagram below shows this process.`}</p>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/howitworks3.png" imageAlt='Stun server' mdxType="FullWidthImage" />
    <p>{`Unfortunately, things can go wrong. When this happens, the TURN (Traversal Using Relays around NAT) server uses its publicly available IP address so both clients can relay information in that way instead.`}</p>
    <p>{`At this point, the connection is no longer P2P, so we lose all the benefits of real-time communication. Consequently, the connection quality degrades and costs increase significantly. Of course, the decision to build a TURN server is not mandatory but, statistically, we see that in 10% of cases, it is not possible to establish a P2P connection through a STUN server. Therefore, in most situations, it is not worth trying to save money by not providing one.`}</p>
    <p>{`In this situation, client B (the external device that we, `}{`”`}{`client A`}{`”`}{`, are trying to reach) only needs to accept a connection. The users can then start exchanging data.`}</p>
    <h2 {...{
      "id": "Summary"
    }}>{`Summary`}</h2>
    <p>{`It can be a challenge to absorb all of these concepts the first time around. So, let`}{`’`}{`s go through the topic again, outlining the most important ideas to understand.`}</p>
    <p>{`Compatibility - WebRTC works perfectly with all popular desktop browsers and many mobile browsers too. However, assuming a mobile product that offers the highest standard is preferable, building a dedicated application is highly recommended.`}</p>
    <p>{`Key concepts:`}</p>
    <p>{`P2P - A communication model used by WebRTC that bypasses intermediaries when sending information. Audio/video/files are transferred directly from one device to another, which significantly reduces delays.`}</p>
    <p>{`NAT - An intermediary between LAN (local network area) and public (The Internet). It hides the public IP address needed to establish a connection.`}</p>
    <p>{`Signalling - A process that enables a secure, direct connection between two devices. An external server steps in temporarily during this process, leaving as soon as the connection exists.`}</p>
    <p>{`STUN server - A server that acts as a proxy when trying to establish a connection, finding out and sending back information about the public IP of the devices that need the P2P connection.`}</p>
    <p>{`TURN server - A proxy server to provide a connection when the STUN server is impossible cannot establish a P2P pairing. The connection mediated by the TURN server does not offer the benefits of P2P.`}</p>
    <p>{`ICE - This process uses the STUN Server when trying to establish a connection. If this fails, ICE tries to establish a connection through the TURN server instead.`}</p>
    <BannerMobileApp title="" description={"Do you want to create a video-chat application?"} mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "Conclusions"
    }}>{`Conclusions`}</h2>
    <p>{`WebRTC is a capable and powerful technology. It offers the highest quality audio/video/file transfer with minimal delays. In addition, it provides the highest quality standards of security because the connection between call members is established directly between participants and is not available to anyone outside.`}</p>
    <p>{`We`}{`’`}{`ve reached the end of this article. We hope you understand how WebRTC works, its limitations and possibilities, and why you should be interested in this technology.`}</p>
    <p>{`If you still have any questions or want to create an application that uses real-time communication, please get in touch. As a company that works with WebRTC and has released a product of its own based on this technology, we definitely can help you turn your idea into a functional application.`}</p>
    <FullWidthImage imageSrc="/img/whyWebRTCisBest/captiobanner.png" imageAlt='Stun server' mdxType="FullWidthImage" />
    <p>{`If you are interested in the options for 3rd party SDKs and which ones might be best for you, check out `}{`”`}{`title of the article`}{`”`}{`. This article compares the best SDKs for mobile platforms currently available on the market.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      